import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import AdminProcedureStore from 'admin/state/procedures';
import AppStore from 'admin/state/stores/app_store';
import CommentStore from 'state/comments';
import DepartmentStore from 'admin/state/stores/department_store';
import DocumentActivityStore from './stores/document_activity_store';
import DocumentStore from 'state/documents';
import FolderStore from 'state/folders';
import FormBuilderStore from 'admin/state/stores/form_builder_store';
import FormStore from 'admin/state/stores/form_store';
import JobRoleStore from 'admin/state/stores/job_role_store';
import LineWorkAreaStore from 'admin/state/stores/line_work_area_store';
import ProposalReviewStore from 'state/proposal_reviews';
import ProcedureStore from './stores/procedure_store';
import ProcessCategoryStore from 'admin/state/stores/process_category_store';
import ProcessStore from 'state/processes';
import ShiftStore from 'admin/state/stores/shift_store';
import MachineTypeStore from 'admin/state/stores/machine_type_store';
import MachineStore from 'admin/state/stores/machine_store';
import ProcessAlertStore from 'admin/state/stores/process_alert_store';
import TaskEventStore from './stores/task_event_store';
import SubmissionStore from 'admin/state/stores/submission_store';
import TaskRuleStore from 'admin/state/task_rules';
import TaskStore from 'state/tasks';
import TextOverflowModalStore from 'state/text_overflow_modal';
import ToastStore from 'state/toast';
import UserAccessActivitiesStore from 'admin/state/stores/user_access_activities_store';
import UserStore from 'admin/state/users';
import VersionStore from 'state/versions';
import session from 'state/session';

const store = new Vuex.Store({
  modules: {
    AdminProcedureStore,
    AppStore,
    CommentStore,
    DepartmentStore,
    DocumentActivityStore,
    DocumentStore,
    FolderStore,
    FormStore,
    FormBuilderStore,
    JobRoleStore,
    LineWorkAreaStore,
    MachineStore,
    MachineTypeStore,
    ProcedureStore,
    ProcessCategoryStore,
    ProcessAlertStore,
    ProcessStore,
    session,
    ShiftStore,
    SubmissionStore,
    TaskEventStore,
    TaskRuleStore,
    TaskStore,
    TextOverflowModalStore,
    ToastStore,
    UserAccessActivitiesStore,
    UserStore,
    VersionStore,
    ProposalReviewStore
  }
});

export default store;
