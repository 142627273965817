



























































































import Vue from 'vue';
import { getLockedValue } from 'admin/components/forms/elements/lockWrapper';

export default Vue.extend({
  props: ['model', 'control', 'history', 'isLocked', 'lockWrapper', 'taskStatus'],
  data() {
    return { showHistory: false };
  },
  computed: {
    buttonLabel() {
      return this.showHistory ? '⊖' : '⊕';
    },
    hasHistory() {
      if (['FileUpload', 'ActionItem'].indexOf(this.control.type) !== -1) {
        return this.history && this.history.length > 1;
      }
      return this.history && this.history.length;
    }
  },
  created() {
    if (typeof $ !== 'function') {
      return;
    }
    $(document).ready(() => {
      const contentId = `lock-field-${this.control.name}`;
      $('[data-toggle="popover"]').popover({
        html: true,
        content: $(contentId) as any
      }).on('show.bs.popover', () => {
        $(contentId).addClass('show');
      }).on('hide.bs.popover', () => {
        $(contentId).addClass('hide');
      });
    });
  },
  methods: {
    getValue(historicValue: any) {
      const v = getLockedValue(historicValue);
      if (v === null || v === '') {
        return '[No value provided]';
      }
      switch(this.control.type) {
      case 'NumberCheckField':
        return this.withinRange(historicValue) ? v : `${v} (Number outside of acceptable range)`;
      default: return v;
      }
    },
    classForValue(historicValue: any) {
      const v = getLockedValue(historicValue);
      switch(this.control.type) {
      case 'DoubleBlind': return v[0] === v[1] ? '' : 'u-color-danger';
      case 'NumberCheckField': return this.withinRange(v) ? '' : 'u-color-danger';
      default: return '';
      }
    },
    withinRange(historicValue: number) {
      return historicValue <= this.control.max && historicValue >= this.control.min;
    }
  }
});
