









import { getLockedValue } from 'admin/components/forms/elements/lockWrapper';

export default {
  props: {
    model: {
      type: String,
      required: false
    },
    title: {
      type: String,
      default: 'Text'
    },
    limit: {
      type: Number,
      default: 50
    }
  },
  computed: {
    value() {
      return getLockedValue(this.model);
    },
    truncate() {
      return !!this.value && this.value.length > this.limit;
    },
    truncatedValue() {
      return this.truncate ? this.value.slice(0, this.limit) + '...' : this.value;
    }
  },
  methods: {
    openTruncatedDialog() {
      this.$store.commit('TextOverflowModalStore/setText', this.value);
      this.$store.commit('TextOverflowModalStore/setTitle', this.title);
      this.$root.$emit('bv::show::modal', 'textOverflowModal');
    }
  }
};
