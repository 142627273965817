import { isNil } from 'ramda';
import Vue from 'vue';

function getValueFromBinding(binding) {
  if (typeof binding.value.value === 'object' && binding.value.value !== null && !(binding.value.value instanceof Array)) {
    return binding.value.value.value;
  } else {
    return binding.value.value;
  }
}

Vue.directive('redBackgroundColor', {
  inserted: function (el, binding) {
    /* eslint-disable no-unreachable */
    return false; // TODO: Remove this line when Mars Petcare project is ready
    const addClass = () => el.classList.add('u-bg-danger-opacity-25');
    if (!binding.value || !binding.value.control) return;
    if (binding.value.control.type === 'NumberCheckField') {
      const fieldValue = getValueFromBinding(binding);
      const withinRange = (fieldValue <= binding.value.control.max && fieldValue >= binding.value.control.min) || isNil(binding.value.value);
      if (!withinRange) {
        addClass();
      }
    }
    if (binding.value.control.type === 'DoubleBlind') {
      const fieldValue = getValueFromBinding(binding);
      if (fieldValue[0] !== fieldValue[1]) {
        addClass();
      }
    }
    /* eslint-enable no-unreachable */
  }
});
