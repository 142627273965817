import { Option, map } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { clone, pathOr } from 'ramda';
import { displayResourceLockedMsg } from 'admin/helpers';
import {
  ExtendedTask,
  UpdateTaskPayload,
  UpdateTaskData,
  NotifyAssigneePayload
} from 'state/tasks/types';

const getCurrentLineWorkArea = (): number => {
  const stringifiedSettings = localStorage.getItem('user-settings');

  try {
    const settings = JSON.parse(stringifiedSettings);
    return pathOr(null, ['line_work_area', 'id'], settings);
  } catch (e) {
    return null;
  }
};

export default {
  get(context) {
    const lineWorkArea = getCurrentLineWorkArea();
    const queryString = lineWorkArea
      ? `?line_work_area_id=${lineWorkArea}`
      : '';
    const userO: Option<User> = context.rootGetters['UserStore/currentUser'];
    return new Promise(resolve => {
      pipe(
        userO,
        map(user => `users/${user.id}/tasks/action-required/${queryString}`),
        map((url: string) => {
          return new Promise((innerResolve, reject) => {
            $.ajax({
              url,
              type: 'get',
              success: data => {
                context.commit('setRequiredTasks', data);
                innerResolve(data);
              },
              error: reject
            });
          }).then(() => resolve());
        })
      );
    });
  },

  getMany(context, taskIds) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: 'tasks/for_ids',
        type: 'post',
        data: { taskIds },
        success: data => {
          data.tasks.map((task: ExtendedTask) => context.commit('SET_TASK', task));
          resolve(data);
        },
        error: reject
      });
    });
  },

  setTasksFromProcess({ commit }, process: Process) {
    const processId = process.id;
    const processState = process.state;

    process.steps.forEach(step => {
      const stepId = step.id;

      step.tasks.forEach(task => {
        const extendedTask: ExtendedTask = {
          ...clone(task),
          stepId,
          processId,
          processState
        };

        commit('SET_TASK', extendedTask);
      });
    });
  },

  async updateTask(context, payload: UpdateTaskPayload) {
    const { processId, stepId, taskId, } = payload;
    const successMsg = payload.successMsg || 'Update was successful.';
    const url = `processes/${processId}/steps/${stepId}/tasks/${taskId}`;

    // Because things are named differently in the db vs. here, rename some keys.
    if (payload.status) {
      payload.statusAction = payload.status;
      delete payload.status;
    }

    const data: UpdateTaskData = { task: payload };

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'put',
        data,
        success: response => {
          const toastArgs = {
            color: 'success',
            title: 'Success',
            text: successMsg,
          };
          context.commit('UPDATE_TASK', response);
          context.dispatch('ToastStore/toast', toastArgs, { root: true });
          resolve(response);
        },
        error: err => {
          const toastArgs = {
            color: 'danger',
            title: 'Update failed',
            text: 'Sorry there was a problem. Please try again or request assistance.'
          };

          if (err.status === 422) {
            /* do nothing */
          } else if (err.status === 423) {
            displayResourceLockedMsg(context);
          } else {
            context.dispatch('ToastStore/toast', toastArgs, { root: true });
          }

          reject(err);
        }
      });
    });
  },

  async updateTaskStatus(context, payload: UpdateTaskPayload) {
    const { processId, stepId, taskId, } = payload;
    const successMsg = payload.successMsg || 'Update was successful.';
    const url = `processes/${processId}/steps/${stepId}/tasks/${taskId}/update_status`;

    // Because things are named differently in the db vs. here, rename some keys.
    if (payload.status) {
      payload.statusAction = payload.status;
      delete payload.status;
    }

    const data: UpdateTaskData = { task: payload };

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'put',
        data,
        success: response => {
          const toastArgs = {
            color: 'success',
            title: 'Success',
            text: successMsg,
          };
          context.commit('UPDATE_TASK', response);
          context.dispatch('ToastStore/toast', toastArgs, { root: true });
          resolve(response);
        },
        error: err => {
          const toastArgs = {
            color: 'danger',
            title: 'Update failed',
            text: 'Sorry there was a problem. Please try again or request assistance.'
          };

          if (err.status === 422) {
            /* do nothing */
          } else if (err.status === 423) {
            displayResourceLockedMsg(context);
          } else {
            context.dispatch('ToastStore/toast', toastArgs, { root: true });
          }

          reject(err);
        }
      });
    });
  },

  addTask({ commit }, payload) {
    commit('SET_TASK', payload);
  },

  openTasks(_, query) {
    return $.ajax({
      url: 'tasks/open',
      type: 'get',
      data: query,
      success: response => Promise.resolve(response),
      error: err => Promise.reject(err)
    });
  },

  openTaskProcedures(_, query) {
    return $.ajax({
      url: 'tasks/open_task_procedures',
      type: 'get',
      data: query,
      success: response => Promise.resolve(response),
      error: err => Promise.reject(err)
    });
  },

  openTaskProcedure(_, query) {
    return $.ajax({
      url: 'tasks/open_task_procedure',
      type: 'get',
      data: query,
      success: response => Promise.resolve(response),
      error: err => Promise.reject(err)
    });
  },

  clearTasks({ commit }) {
    commit('CLEAR_TASKS');
  },

  setIncompleteTask({ commit }, taskId: string): Promise<void> {
    commit('SET_INCOMPLETE_TASK', taskId);
    return Promise.resolve();
  },

  clearIncompleteTask({ commit }) {
    commit('CLEAR_INCOMPLETE_TASK');
  },

  async removeAssignee({ commit, dispatch }, payload: UpdateTaskPayload) {
    const { processId, stepId, taskId } = payload;
    const url = `processes/${processId}/steps/${stepId}/tasks/${taskId}/assignee`;

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'delete',
        success: response => {
          const toastArgs = {
            color: 'success',
            title: 'Success',
            text: 'Update was successful.'
          };

          dispatch('ToastStore/toast', toastArgs, { root: true });
          commit('REMOVE_ASSIGNEE', payload.taskId);
          resolve(response);
        },
        error: err => {
          const toastArgs = {
            color: 'danger',
            title: 'Failure',
            text: 'Unable to update task.'
          };
          dispatch('ToastStore/toast', toastArgs, { root: true });
          reject(err)
        }
      });
    });
  },

  async notifyAssignee(context, payload: NotifyAssigneePayload) {
    const { assignee, processId, stepId, taskId } = payload;
    const url = `processes/${processId}/steps/${stepId}/tasks/${taskId}/notify_assignee`;

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        type: 'post',
        data: { email: assignee.email },
        success: data => {
          resolve(data);
        },
        error: reject
      })
    });
  }
};
