// The following two imports are for IE11 compatability. If/when we remove
// support for IE11, we can remove these and shrink our package size a bit.
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'whatwg-fetch';

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '../src/state';
import router from '../src/routes';
import '../src/filters/date.ts';
import '../src/filters/fullname.ts';
import '../src/filters/text.ts';
import '../src/directives/redBackgroundColor.ts';
import { isTimeoutOrOffline, displayOfflineMsg } from '../src/admin/helpers';
import EventBus from '../src/admin/event_bus';
import BootstrapVue from 'bootstrap-vue';
import NavTop from '../src/components/shared/_nav_top.vue';
import WxAvatar from '../src/components/shared/_wx_avatar.vue';
import NavSidebar from '../src/components/shared/_nav_sidebar.vue';
import SubmitTag from '../src/admin/components/shared/_submit_tag';
import LocalButton from '../src/admin/components/shared/_local_button.vue';
import WxLabel from '../src/admin/components/forms/elements/WxLabel';
import WxRequired from '../src/admin/components/forms/elements/WxRequired.vue';
import WxErrorMessage from '../src/admin/components/forms/elements/WxErrorMessage';
import ReadonlyFormField from '../src/admin/components/forms/elements/ReadonlyFormField.vue';
import TextTruncation from '../src/components/TextTruncation.vue';
import LockBadge from '../src/admin/components/forms/elements/LockBadge.vue';
import { isAdmin, isSupervisor } from 'admin/helpers/user'
import VueScrollTo from 'vue-scrollto';
import WxModal from '../src/components/shared/_wx_modal.vue';
import { path, keys } from 'ramda';
import { getCookie } from 'helpers/cookies';
import { camelCase } from 'change-case';
import $ from 'jquery';
global.$ = global.jQuery = $;

import 'bootstrap';
import 'select2';

// Expose EventBus to window for JQuery access (ActionCable)
window.EventBus = EventBus;

Vue.component('nav-top', NavTop);
Vue.component('nav-sidebar', NavSidebar);
Vue.component('submit-tag', SubmitTag);
Vue.component('local-button', LocalButton);
Vue.component('wx-avatar', WxAvatar);
Vue.component('wx-label', WxLabel);
Vue.component('lock-badge', LockBadge);
Vue.component('wx-required', WxRequired);
Vue.component('wx-error-message', WxErrorMessage);
Vue.component('readonly-form-field', ReadonlyFormField);
Vue.component('wx-modal', WxModal);
Vue.component('text-truncation', TextTruncation);
Vue.use(BootstrapVue);
Vue.use(VueI18n);
Vue.use(VueScrollTo);

$.ajaxSetup({
  beforeSend: function(xhr) {
    xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
  },
  complete: function(xhr, status) {
    if (isTimeoutOrOffline(xhr.status)) {
      displayOfflineMsg(store);
      return true;
    }
    if (xhr.status === 200 || xhr.status === 201 || xhr.status === 204 || xhr.status === 422) {
      return true;
    }
    if (xhr.status === 401) {
      if (router.currentRoute.name !== 'froot_path') {
        const nextRoute = path(['currentRoute', 'path'], router);
        store.dispatch('session/setNextRoute', nextRoute);
        return window.location.href = '/?forbidden';
      }
      return;
    }
    if (xhr.status === 404) {
      // edit process route has a custom 404 page
      if (router.currentRoute.name !== 'process_edit_path') {
        return window.location.href = '/404';
      }
      return;
    }
    if ([423, 400].includes(xhr.status)) {
      return;
    }

    return window.location.href = '/500';
  }
});

function shouldPrefilter(url) {
  // Do not prefilter urls that contain any of the explict list of skipPrefilterStrings
  const skipPrefilterStrings = ['sign_in', 'sign_out', 'sessions/check'];
  return !skipPrefilterStrings.some( string =>  url.includes(string));
}

$.ajaxPrefilter(function( options ) {
  if (shouldPrefilter(options.url)) {
    options.url = `/${I18n.prefix}api/${options.url}`;
  }
});

const i18n = new VueI18n({
  locale: 'current',
  messages: translations
});

//init theme functions
$(document).ready(function() {
  // Initialize

  const i = setInterval(function() {
    if ($('.c-sidebar-toggle').length > 0) {
      clearInterval(i);
      Sidebar(); // 1. Sidebar
      // Switch();  // 2. Switches
      Toggle();  // 3. Toggles
    }
  }, 10);
});

$(window).resize(function(){
  Sidebar();
});

const loadEvents = (message) => {
  const opts = {
    processId: message.id,
    stepId: message.stepId,
    taskId: message.task.id
  };
  // load events if current modal task id matches message task id
  const modalTaskId = path(['submissionInfo', 'taskId'], store.state.ProcessStore);
  if (modalTaskId === message.task.id) {
    store.dispatch('TaskEventStore/index', opts);
  }
};

const deepCamelCase = (value) => {
  if (Array.isArray(value)) {
    return value.map(deepCamelCase);
  } else if (value && typeof value === 'object') {
    const camelCasedHash = {};
    keys(value).forEach((key) => {
      const camelCasedKey = camelCase(key);
      camelCasedHash[camelCasedKey] = deepCamelCase(value[key]);
    });
  
    return camelCasedHash;
  }
  return value;
};

App.vue = new Vue({
  i18n,
  router,
  store,
  watch: {
    $route: function() {
      Vue.nextTick(Sidebar);
    }
  },
  provide: {
    singleSignOn() {
      const qaCookie = getCookie('qa');
      const isNotQaLogin = !qaCookie || qaCookie.toString() !== 'true';
      return isNotQaLogin && window.App.singleSignOn;
    },
    localizationOverrides() {
      return window.App.localizationOverrides;
    },
    isAdmin() {
      return isAdmin(store.getters['UserStore/currentUser']);
    },
    isSupervisor() {
      return isSupervisor(store.getters['UserStore/currentUser']);
    },
    csrfToken() {
      return $('meta[name="csrf-token"]').attr('content')
    },
    currentUser() {
      return store.getters['UserStore/currentUser']
    }
  },
  methods: {
    receiveMessage(message) {
      let title;
      let text;
      switch (message.key) {
      case 'update_percent_complete':
        store.commit('ProcessStore/updatePercentComplete', message);
        break;
      case 'update_process_summary':
        store.dispatch('ProcessStore/processSummaryUpdated', message);
        break;
      case 'add_step':
        store.dispatch('ProcessStore/stepAdded', message);
        break;
      case 'add_task':
        store.dispatch('ProcessStore/taskAdded', message);
        break;
      case 'update_task_status':
        store.dispatch('ProcessStore/taskStatusUpdated', message);
        loadEvents(message);
        break;
      case 'task_rule_applied':
        store.dispatch('ProcessStore/taskStatusUpdated', message);
        loadEvents(message);
        break;
      case 'task_rule_notifications':
        store.dispatch('ProcessStore/taskRuleToasts', message);
        break;
      case 'update_task_next_check':
        store.dispatch('ProcessStore/taskNextCheckUpdated', message);
        break;
      case 'update_task_last_updated':
        store.dispatch('ProcessStore/taskLastUpdatedUpdated', deepCamelCase(message));
        loadEvents(message);
        break;
      case 'update_process_status':
        store.dispatch('ProcessStore/processStatusUpdated', message);
        break;
      case 'update_single_process':
        store.dispatch('ProcessStore/updateSingleProcess', message);
        store.dispatch('ProcessStore/taskLastUpdatedUpdated', deepCamelCase(message));
        break;
      case 'update_next_task':
        store.dispatch('TaskStore/get');
        break;
      case 'user_ip_notification':
        title = 'Activity Warning';
        text = 'Your account is also logged-in from a device on a different network.';
        this.$store.dispatch('ToastStore/toast', { color: 'warning', duration: null, title, text });
        break;
      case 'submission_copied':
        store.dispatch('ProcessStore/submissionCopied', message);
        break;
      default:
        console.warn('Unknown Message: ', message);
        break;
      }
    }
  }
}).$mount('#app');
